const de = {
  hero: {
    header: "Sandra & Peter",
    "date-location-text": "23.07.2022 - München",
    "photos-button-label": "Zu den Fotos",
  },
  photos: {
    title: "Die Fotos sind da!",
    greeting: "Ihr Lieben,",
    "thank-you":
      "vielen Dank, dass ihr da wart und wir diesen schönen Tag gemeinsam feiern konnten!",
    "have-fun": "Viel Spaß mit den Fotos!",
    "view-registry-office-photos-button-label": "Standesamt",
    "view-fotobox-photos-button-label": "Fotobox",
    "view-botanikum-photos-button-label": "Hochzeit",
  },
  rsvp: {
    "code-entry-header": "Sandra & Peter",
    "code-entry-text": "Bitte gib Deinen Code von Deiner Einladung ein.",
    "code-entry-input-label": "Code",
    "code-entry-input-placeholder": "z.B.: ABCD",
    "code-entry-button-label": "Weiter",
    "code-entry-input-error-check-code": "Bitte überprüfe Deinen Code.",
    "code-entry-input-error-check-network":
      "Dein Code konnte nicht überprüft werden. Bitte überprüfe Deine Internetverbindung.",
    "code-entry-input-error-empty-submission":
      "Du musst deinen Einladungscode eingeben.",
  },
};

export default de;
