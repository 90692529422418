import "./index.css";
import "react-slideshow-image/dist/styles.css";

import ReactDOM from "react-dom/client";
import AppThemeContainer from "./components/AppThemeContainer/AppThemeContainer";

const container = document.getElementById("root");
const reactRoom = ReactDOM.createRoot(container!);

reactRoom.render(<AppThemeContainer />);
