import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Content from "../Content/Content";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

function scrollToRef(ref: React.RefObject<HTMLDivElement>) {
  return () => {
    const { current } = ref;
    if (current) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };
}

const useStyles = makeStyles({
  app: {
    textAlign: "center",
    backgroundColor: "#fcf7f0",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const App: React.FC = () => {
  const firstSectionRef = useRef<HTMLDivElement>(null);

  const scrollToFirstSection = scrollToRef(firstSectionRef);

  const styles = useStyles();

  return (
    <Box className={styles.app}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Content
                firstSectionRef={firstSectionRef}
                scrollToFirstSection={scrollToFirstSection}
              />
            }
          />
          <Route
            path=":rsvp_code"
            element={
              <Content
                firstSectionRef={firstSectionRef}
                scrollToFirstSection={scrollToFirstSection}
              />
            }
          />
        </Routes>
      </Router>
    </Box>
  );
};

export default App;
