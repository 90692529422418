import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Fade } from "react-slideshow-image";

interface SlideshowContainerProps {
  link: string;
  photos: string[];
  label: string;
}

const useStyles = makeStyles({
  slideshowContainer: {
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
  },
  slideshowImage: {
    maxHeight: "400px",
    maxWidth: "100%",
    objectFit: "contain",
  },
  viewPhotosButtonLink: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    margin: "0 auto",
    zIndex: 1,
  },
  viewPhotosButton: {
    background: "#9DA880",
  },
});

const SlideshowContainer: React.FC<SlideshowContainerProps> = ({
  link,
  photos,
  label,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.slideshowContainer}>
      <Fade pauseOnHover={false} arrows={false}>
        {photos.map((image) => (
          <Link
            key={image}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <img alt="" src={image} className={styles.slideshowImage} />
          </Link>
        ))}
      </Fade>
      <Link
        href={link}
        className={styles.viewPhotosButtonLink}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Button
          variant="contained"
          className={styles.viewPhotosButton}
          color="secondary"
        >
          {label}
        </Button>
      </Link>
    </Box>
  );
};

export default SlideshowContainer;
