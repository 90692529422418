import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useSWR from "swr";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Hero from "../Hero/Hero";
import RSVP from "../RSVP/RSVP";
import { fetcher } from "../../lib/fetcher";
import { buildValidateRSVPCodeUrl } from "../../lib/build-validate-rsvp-code-url";
import PhotosSection from "../PhotosSection/PhotosSection";

interface ContentProps {
  scrollToFirstSection: () => void;
  firstSectionRef: React.RefObject<HTMLDivElement>;
}

enum BackgroundType {
  Code,
  Full,
}

function contentBackground(theme: Theme, backgroundType: BackgroundType) {
  return {
    [theme.breakpoints.up("md")]: {
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    backgroundColor: "#fcf7f0",
    backgroundAttachment: "scroll",
    backgroundRepeat:
      backgroundType === BackgroundType.Code ? "no-repeat" : "repeat-y",
    backgroundSize:
      backgroundType === BackgroundType.Code ? "cover" : "contain",
    backgroundImage: "url(https://static.juras.wedding/content-bg-2.jpg)",
    height: backgroundType === BackgroundType.Code ? "100vh" : undefined,
    width: "100%",
  };
}

const useStyles = makeStyles((theme) => ({
  contentBackgroundCode: contentBackground(theme, BackgroundType.Code),
  contentBackgroundFull: contentBackground(theme, BackgroundType.Full),
  rsvpContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
}));

interface ValidationResultValid {
  valid: true;
  registryOfficePhotosLink: string;
  fotoboxPhotosLink: string;
  weddingPhotosLink: string;
}

interface ValidationResultFalse {
  valid: false;
}

type ValidationResult = ValidationResultValid | ValidationResultFalse;

const Content: React.FC<ContentProps> = ({
  firstSectionRef,
  scrollToFirstSection,
}) => {
  const params = useParams();
  const [rsvpCode, setRsvpCode] = useState(params.rsvp_code || "");
  const finalRsvpCode = rsvpCode || params.rsvp_code || "";

  const validateRSVPCodeUrl = finalRsvpCode
    ? buildValidateRSVPCodeUrl(finalRsvpCode)
    : null;
  const validateRSVPCodeResult = useSWR<ValidationResult>(
    validateRSVPCodeUrl,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  const styles = useStyles();

  function onSubmitRSVPCode(rsvpCode: string): void {
    setRsvpCode(rsvpCode);
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {validateRSVPCodeResult.data?.valid ? (
          <motion.div
            key="invitation-available"
            style={{ width: "100%" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Hero
              showDate={validateRSVPCodeResult.data.valid}
              scrollToFirstSection={scrollToFirstSection}
            />
            <Box className={styles.contentBackgroundFull}>
              <PhotosSection
                registryOfficePhotosLink={
                  validateRSVPCodeResult.data.registryOfficePhotosLink
                }
                fotoboxPhotosLink={
                  validateRSVPCodeResult.data.fotoboxPhotosLink
                }
                weddingPhotosLink={
                  validateRSVPCodeResult.data.weddingPhotosLink
                }
                ref={firstSectionRef}
              />
            </Box>
          </motion.div>
        ) : (
          <motion.div
            key="invitation-unavailable"
            style={{ width: "100%" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box className={styles.contentBackgroundCode}>
              <Box className={styles.rsvpContainer}>
                <RSVP
                  setRsvpCode={onSubmitRSVPCode}
                  loading={validateRSVPCodeResult.isValidating}
                  validateRSVPCodeError={validateRSVPCodeResult.error}
                  validateRSVPCodeResult={validateRSVPCodeResult.data?.valid}
                />
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Content;
