import React, { forwardRef } from "react";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { theme } from "../../theme";

const paperBaseStyle = {
  [theme.breakpoints.up("md")]: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  paddingTop: "50px",
  paddingBottom: "50px",
  position: "relative" as "relative",
  zIndex: 1,
};

const useStyles = makeStyles({
  container: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "8vw",
      paddingRight: "8vw",
    },
    paddingTop: "100px",
    paddingBottom: "100px",
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
  },
  paper: paperBaseStyle,
  paperWithPadding: {
    ...paperBaseStyle,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  outerBorder: {
    [theme.breakpoints.up("md")]: {
      left: "5%",
      right: "5%",
    },
    border: "10px",
    borderStyle: "solid",
    borderImageSource: "linear-gradient(to bottom, #C19F7A, #FCF3E0)",
    borderImageSlice: 1,
    position: "absolute",
    pointerEvents: "none",
    left: 0,
    right: 0,
    zIndex: 2,
    top: 40,
    bottom: 40,
  },
  outerTransparentBorder: {
    backgroundColor: "#FFFFFFCC",
    position: "absolute",
    top: 55,
    left: "12.5%",
    right: "12.5%",
    zIndex: 0,
    bottom: 55,
  },
});

interface SectionBaseProps {
  children: React.ReactNode;
  disablePaperPadding?: boolean;
}

const SectionBase = forwardRef<HTMLDivElement, SectionBaseProps>(
  ({ children, disablePaperPadding }, ref) => {
    const styles = useStyles();

    const paperClassName = disablePaperPadding
      ? styles.paper
      : styles.paperWithPadding;

    return (
      <Container className={styles.container} ref={ref}>
        <div className={styles.outerBorder} />
        <div className={styles.outerTransparentBorder} />
        <Paper className={paperClassName} elevation={0} square>
          {children}
        </Paper>
      </Container>
    );
  },
);

export default SectionBase;
