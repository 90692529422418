import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import makeStyles from "@mui/styles/makeStyles";
import strings from "../../strings/de";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100vh",
  },
  root: {
    [theme.breakpoints.up("lg")]: {
      backgroundPositionY: "-850px",
      backgroundAttachment: "fixed",
    },
    height: "100%",
    backgroundImage:
      "url(https://static.juras.wedding/hero-wedding-botanikum.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  topTitleContainer: {
    height: "30%",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)",
  },
  bottomContainer: {
    height: "20%",
    background:
      "linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)",
  },
  dateText: {
    color: "white",
    fontWeight: "bold",
  },
  whiteText: {
    color: "white",
  },
  button: {
    color: "white",
    "&:hover": {
      backgroundColor: "#FFFFFF33",
    },
  },
  arrowButton: {
    color: "white",
  },
  titleText: {
    height: "50%",
  },
  dateTextContainer: {
    height: "20%",
  },
}));

interface HeroProps {
  scrollToFirstSection: () => void;
  showDate: boolean;
}

const Hero: React.FC<HeroProps> = ({ scrollToFirstSection, showDate }) => {
  const styles = useStyles();

  return (
    <Box className={styles.rootContainer}>
      <Grid
        container
        component="main"
        direction="column"
        className={styles.root}
        alignItems="stretch"
        justifyContent="space-between"
      >
        <Grid
          container
          item
          className={styles.topTitleContainer}
          direction="column"
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid
            container
            item
            alignItems="flex-end"
            justifyContent="center"
            className={styles.titleText}
          >
            <Grid item>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.75 } }}
              >
                <Typography variant="h1" className={styles.whiteText}>
                  {strings.hero.header}
                </Typography>
              </motion.div>
            </Grid>
          </Grid>
          <Grid
            item
            alignItems="center"
            container
            justifyContent="center"
            className={styles.dateTextContainer}
          >
            <motion.div
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { delay: 0.85 } },
              }}
              initial={{ opacity: 0 }}
              animate={showDate ? "visible" : "hidden"}
            >
              <Typography className={styles.dateText} variant="h2">
                {strings.hero["date-location-text"]}
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid />
        <Grid
          className={styles.bottomContainer}
          container
          item
          alignItems="flex-end"
        >
          <Grid item container justifyContent="center">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.95 } }}
            >
              <Button
                onClick={scrollToFirstSection}
                variant="outlined"
                className={styles.button}
                color="inherit"
              >
                {strings.hero["photos-button-label"]}
              </Button>
            </motion.div>
          </Grid>
          <Grid item container alignSelf="center" justifyContent="center">
            <Button
              onClick={scrollToFirstSection}
              className={styles.arrowButton}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1.05 } }}
              >
                ▽
              </motion.div>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
