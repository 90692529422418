import { createTheme } from "@mui/material/styles";
import { buildRem } from "./util/build-rem";

export const SECONDARY_COLOR = "#334919";

const laParisienneName = "La Parisienne";
const luloName = "LuloCleanW01-One";
const arquitectaName = "Arquitecta W00 Regular";
export const arquitectaBoldName = "Arquitecta W00 Bold";

const baseTheme = createTheme();

export const fontSizeConfigs = {
  body1: {
    default: 1.3,
    upMd: 1.4,
  },
} as const;

export const theme = createTheme({
  palette: {
    primary: {
      main: SECONDARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  typography: {
    fontFamily: arquitectaName,
    h1: {
      fontFamily: laParisienneName,
    },
    h2: {
      fontFamily: luloName,
      marginBottom: 20,
      fontSize: buildRem(1),
      [baseTheme.breakpoints.up("md")]: {
        fontSize: buildRem(1.1),
      },
    },
    h3: {
      [baseTheme.breakpoints.up("md")]: {
        fontSize: buildRem(1.3),
      },
      fontFamily: arquitectaBoldName,
      fontSize: buildRem(1.3),
      color: "black",
    },
    h4: {
      fontFamily: arquitectaBoldName,
    },
    h5: {
      fontFamily: arquitectaName,
    },
    body1: {
      [baseTheme.breakpoints.up("md")]: {
        fontSize: buildRem(fontSizeConfigs.body1.upMd),
      },
      fontSize: buildRem(fontSizeConfigs.body1.default),
      fontFamily: arquitectaName,
    },
    body2: {
      [baseTheme.breakpoints.up("md")]: {
        fontSize: buildRem(1.1),
      },
      fontSize: buildRem(1),
      fontFamily: arquitectaName,
    },
    button: {
      fontFamily: arquitectaName,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: ${laParisienneName};
          font-style: Regular;
          font-display: block;
          font-weight: 400;
          src: local('${laParisienneName}'), url(/la-parisienne.ttf);
        }

        @font-face {
          font-family: ${luloName};
          font-style: Regular;
          font-display: block;
          font-weight: 300;
          src: local('${luloName}'), url(/lulo.ttf);
        }

        @font-face {
          font-family: ${arquitectaName};
          font-style: Regular;
          font-display: block;
          font-weight: 400;
          src: local('${arquitectaName}'), url(/arquitecta.ttf)
        }

        @font-face {
          font-family: ${arquitectaBoldName};
          font-style: Bold;
          font-display: block;
          font-weight: 700;
          src: local('${arquitectaBoldName}'), url(/arquitecta-bold.ttf)
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          [baseTheme.breakpoints.up("md")]: {
            fontSize: buildRem(1.35),
          },
          fontSize: buildRem(1.25),
        },
      },
    },
  },
});
