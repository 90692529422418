import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import MUIButton from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  absolute: {
    position: "absolute",
    color: "rgba(0, 0, 0, 0.12)",
  },
  disabled: {
    color: "transparent !important",
  },
});

interface ButtonProps {
  label: string;
  loading: boolean;
  error?: boolean;
  done?: boolean;
  onClick: Function;
}

const Button: React.FC<ButtonProps> = ({
  done,
  label,
  error,
  loading,
  onClick,
}) => {
  const styles = useStyles();

  let content: string | JSX.Element = "";
  if (loading) {
    content = <CircularProgress className={styles.absolute} size={24} />;
  } else if (done) {
    content = <CheckIcon className={styles.absolute} fontSize="large" />;
  }
  if ((loading || done) && error) {
    content = <ErrorIcon className={styles.absolute} fontSize="large" />;
  }

  return (
    <MUIButton
      classes={{
        disabled: styles.disabled,
      }}
      disabled={loading || done}
      variant="outlined"
      onClick={() => onClick()}
      size="large"
    >
      {content}
      {label}
    </MUIButton>
  );
};

export default Button;
