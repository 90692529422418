import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import SectionBase from "../SectionBase/SectionBase";
import strings from "../../strings/de";
import SlideshowContainer from "./SlideShowContainer";

const REGISTRY_OFFICE_SLIDESHOW_IMAGES = new Array(4)
  .fill("")
  .map(
    (_, index) =>
      `https://static.juras.wedding/slideshow-photo-${index + 1}.jpg`,
  );

const FOTOBOX_SLIDESHOW_IMAGES = new Array(5)
  .fill("")
  .map(
    (_, index) =>
      `https://static.juras.wedding/slide-fotobox-photo-${index + 1}.jpg`,
  );

const WEDDING_SLIDESHOW_IMAGES = new Array(5)
  .fill("")
  .map(
    (_, index) =>
      `https://static.juras.wedding/slide-wedding-photo-${index + 1}.jpg`,
  );

const useStyles = makeStyles({
  firstLine: {
    marginBottom: 10,
  },
});

interface PhotosSectionProps {
  fotoboxPhotosLink: string;
  registryOfficePhotosLink: string;
  weddingPhotosLink: string;
}

const PhotosSection = React.forwardRef<HTMLDivElement, PhotosSectionProps>(
  ({ fotoboxPhotosLink, registryOfficePhotosLink, weddingPhotosLink }, ref) => {
    const styles = useStyles();

    return (
      <SectionBase ref={ref}>
        <Typography variant="h2">{strings.photos.title}</Typography>
        <Typography className={styles.firstLine}>
          {strings.photos.greeting}
        </Typography>
        <Typography>{strings.photos["thank-you"]}</Typography>
        <Typography>{strings.photos["have-fun"]}</Typography>
        <SlideshowContainer
          photos={WEDDING_SLIDESHOW_IMAGES}
          link={weddingPhotosLink}
          label={strings.photos["view-botanikum-photos-button-label"]}
        />
        <SlideshowContainer
          photos={FOTOBOX_SLIDESHOW_IMAGES}
          link={fotoboxPhotosLink}
          label={strings.photos["view-fotobox-photos-button-label"]}
        />
        <SlideshowContainer
          photos={REGISTRY_OFFICE_SLIDESHOW_IMAGES}
          link={registryOfficePhotosLink}
          label={strings.photos["view-registry-office-photos-button-label"]}
        />
      </SectionBase>
    );
  },
);

export default PhotosSection;
