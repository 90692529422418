import { forwardRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "../Button/Button";
import { makeStyles } from "@mui/styles";
import strings from "../../strings/de";
import SectionBase from "../SectionBase/SectionBase";

interface RSVPProps {
  setRsvpCode: (rsvpCode: string) => void;
  loading: boolean;
  validateRSVPCodeError: Error;
  validateRSVPCodeResult: boolean | undefined;
}

const useStyles = makeStyles({
  codeInput: {
    marginTop: 10,
    width: "90%",
    marginBottom: 10,
  },
});

const RSVP = forwardRef<HTMLDivElement, RSVPProps>(
  (
    { loading, setRsvpCode, validateRSVPCodeError, validateRSVPCodeResult },
    ref,
  ) => {
    const styles = useStyles();
    const [code, setCode] = useState("");
    const [emptySubmission, setEmptySubmission] = useState(false);

    function onClickHandler() {
      if (code) {
        setRsvpCode(code.toUpperCase());
      } else {
        setEmptySubmission(true);
      }
    }

    let errorHelperText = "";
    if (!loading) {
      if (validateRSVPCodeError) {
        errorHelperText = strings.rsvp["code-entry-input-error-check-network"];
      } else if (validateRSVPCodeResult === false) {
        errorHelperText = strings.rsvp["code-entry-input-error-check-code"];
      } else if (emptySubmission) {
        errorHelperText =
          strings.rsvp["code-entry-input-error-empty-submission"];
      }
    }

    return (
      <SectionBase ref={ref}>
        <Typography variant="h2">
          {strings.rsvp["code-entry-header"]}
        </Typography>
        <Typography>{strings.rsvp["code-entry-text"]}</Typography>
        <TextField
          autoFocus
          label={strings.rsvp["code-entry-input-label"]}
          placeholder={strings.rsvp["code-entry-input-placeholder"]}
          error={!!errorHelperText}
          helperText={errorHelperText}
          className={styles.codeInput}
          variant="outlined"
          value={code.toUpperCase()}
          inputProps={{
            autoCapitalize: "characters",
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              onClickHandler();
            }
          }}
          onChange={({ target: { value } }) => {
            setEmptySubmission(false);
            setCode(value);
          }}
        />
        <Box>
          <Button
            onClick={onClickHandler}
            loading={loading}
            label={strings.rsvp["code-entry-button-label"]}
          />
        </Box>
      </SectionBase>
    );
  },
);

export default RSVP;
